

.guestPage{
   &__icon-stat{
    margin-right: 0.3rem;
    margin-left: 0.3rem;
   }
   &__clic:hover {
    cursor: zoom-in;
}
}