@use 'vars' as v;
@use 'reset';

.app{
  overflow-x: hidden
}
body {
  font-family: 'Roboto', sans-serif;
  max-height: 100vh;
  line-height: 24px;
  max-width: 100vw;
  background-color: v.$primaryColor;
  overflow-x: hidden;
}


::-webkit-scrollbar-track
{
  border: 5px solid white;
  background-color: v.$primaryColor;
}

::-webkit-scrollbar
{
  width: 6px;
  height: 6px;
  background-color: #dfe6e9;
}


@font-face {
        font-family: 'Roboto', sans-serif;
        src: url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;1,500&display=swap');
        font-weight: bold;
        font-style: italic;
}
// @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;1,500&display=swap');

@media only screen and (max-width: 768px) {
.empty-div{
    padding:2rem;
  }
}