.homeOrganizer {
    &__img {
        background-image: url('../../assets/img/home.jpg');
        background-repeat: no-repeat;
        background-size: 60%;
        background-position: bottom;
    }
}

@media only screen and (max-width: 767px) {
.homeOrganizer {
    &__img {
        background-size: 70%;
        background-position: center;
        padding-bottom: 0;
    }
}
  
}