
#Default{
    font-family: 'Sacramento', cursive;
}

#Rubik-Microbe{
    font-family:'Rubik Microbe', cursive;
}
#Sono{
    font-family: 'Sono', sans-serif;
}

#Dancing-Script{
    font-family:'Dancing Script', cursive;
}

#Anton{
    font-family: 'Anton', sans-serif;
}