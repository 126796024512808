@use "../../../vars" as v;
.agenda {
  &__container {
    display: flex;
    min-width: 100%;
    // justify-content: right;
    flex-direction: raw;
    padding: 0 2rem;
  }

  &__container-events {
    width: 80%;
    display: flex;
    flex-direction: column;
    max-height: 32rem;
    overflow-y: scroll;
  }

  &__container-event {
    border-radius: 5px;
    background-color: v.$whiteColor;
    padding: 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
    box-shadow: v.$shadow;
    display: flex;
    justify-content: space-between;
    padding: 2.7rem 1rem;
  }
  &__container-event-icon-text {
    display: flex;
    flex-direction: row;
  }
  &__event-text-not-important {
    font-weight: 300;
  }
  &__event-text-important {
    font-weight: 500;
  }
  &__event-address {
    font-style: italic;
    font-weight: 300;
    &:hover {
      filter: v.$filter;
      text-decoration: underline;
    }
  }

  &__event-icon {
    padding: 1rem;
    width: 3.5rem;
  }

  &__container-img {
    width: 40%;
    height: 32rem;
    display: flex;
    justify-content: center;
  }

  &__img {
    border-radius: 5px;
    max-width: 100%;
    max-height: 100%;
    padding-left: 1rem;
   // display: block;
    margin: auto;
    
    // filter: v.$filter;
  }

  &__container-separator {
    width: 5%;
  }
  &__separator {
    border-radius: 5px;
    max-width: 100%;
  }
  &__add-event-btn {
    padding: 4rem 2rem;
    
  }
  //   &__event-btn-icon{
  //  position: relative;
  //     top: 0rem;
  //     left: 24rem;
  //     width: 6rem;
  //     border: 2px solid blue;
  //     text-align: center;
  //    }
}
#agenda__add-event-btn {
  margin: 1rem 0rem 2rem 2rem;
}



@media only screen and (max-width: 767px) {
  
  .agenda {
    &__container {
    padding: 0 1rem;
  }
  
  &__container-events {
    width: 100%;
    
  }
&__container-event {
    padding-left: 0.1rem;
    padding-top: 1rem;
    padding-right: 0.1rem;
    padding-bottom: 1rem;
  }
  &__event-icon {
    padding: 0.4rem;
    width: 3rem;
    margin-bottom: 3.8rem;
  }
  }
}