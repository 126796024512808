@use "../../../vars" as v;

.mysvg:hover {
  filter: invert(100%);
  //invert(0.5); invert(100%)
}

.header {
  padding: 1.5rem 0 1.5rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1.5rem;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 3px 5px;
  padding-bottom: 0.6rem;
  
&__highlight {
    visibility: hidden;
  }
}


@media only screen and (max-width: 767px) {
  
   .header {
     padding: 0rem;
     flex-direction: column;
    &__title {
     padding-left: 1rem;
     text-align: center;
     margin-left: 2rem;
     padding-right: 0rem;
   }
   &__container-date-profile{
     display: flex;
     flex-direction: row;
     justify-content: space-around; 
   }
    
   }
  
}
