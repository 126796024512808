@use "../../../vars" as v;
.rsvp {
&__container-img {
    width: 100%;
    // justify-content: right;
    height: 28rem;
    //border: 2px solid black;
   //background-color: beige;
    // display: flex;
    justify-content: center;
  }

&__img {
    //border-radius: 5px;
    //border-radius: 10px 100px / 120px;
    max-width: 100%;
    max-height: 100%;
    padding-left: 5rem;
   // display: block;
    margin: auto;
    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
    // filter: v.$filter;
  }

}