.addEventForm {
  &__separator {
    border: none;
    border-top: 1px dotted #333;
    color: #333;
    overflow: visible;
    text-align: center;
    height: 5px;
    &:before {
      background: #fff;
      content: attr(data-before);
      padding: 0 4px;
      position: relative;
      top: -13px;
    }
  }
  &__icon-label{
    border: 1px solid black;
    padding: 0.5rem; 
  }
}
