.location-pin{
    
}

.pin-info{
    &__container{
        width: 10rem;
        height: 10rem;
        background-color: white;
        border: 1px black solid;
        padding:1rem;
        font-size: 1rem;
        // display: flex;
        // flex-direction: column;
        // z-index: 100;
    }
}