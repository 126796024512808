@use '../../../vars' as v;

.mysvg:hover {
  filter: invert(100%);
}

.menu {
  

  &__navbar {
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    a , button {
      display: flex;
      max-width: 6rem;
      max-height: 6rem;
      align-items: center;
      flex-direction: column;
    }
  }

  &__selected {
    .header__highlight {
      margin-top: 0.5rem;
      visibility: visible;
    }
  }
  

}



@media only screen and (max-width: 767px) {
  
  .menu {
    // width:100vh;
    padding-top: 0.4rem;
    display: flex;
    flex-direction: column;
    &__navbar {
      box-shadow: 0 3px 8px rgb(0 0 0 / 29%);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      position: fixed;
      bottom:0;
      z-index: 10000;

      a, button{
        display: flex;
        max-width: 3rem;
        max-height: 3rem;
        align-items: center;
        flex-direction: column;
      }
    }
    
  }
  
}