.homeGuest {
    &__img {
        background-image: url('../../assets/img/homeGuest.png');
        background-repeat: no-repeat;
        background-size: 40%;
        background-position: center;
    }
}

@media only screen and (max-width: 767px) {
.homeGuest {
    &__img {
        background-size: 50%;
    }
}
  
}