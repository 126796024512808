@use '../../vars' as v;

.title{
  &__img--title{
    display: flex;
    padding-left:2rem;
  }
  &__star{
    //padding-left: 1rem;
    margin-top: 1.8rem;
  }
 &__title {
    font-size: 2.4rem;
    padding: 1.5rem 1.5rem;
    margin: 3rem 0 2rem 0;
  }
}


@media only screen and (max-width: 768px) {
  .title {
    &__title {
      padding-left: 0.7rem;
      
    }
  }
}
