@use "../../../vars" as v;

.header {
  
  padding: 1.5rem 0 1.5rem 0;

  &__title {
    font-size: 3rem;
    line-height: 3rem;
    text-align: center;
    padding-right: 7rem;
    text-align: center;
    margin-top: 1rem;
    // padding-top: 0rem;
  }

  &__container-date-profile{
    display: flex;
    padding-right: 1rem;
  }

  &__date {
    padding-right: 1rem;
    padding-top: 0.5rem;
    font-size: 2rem;
    margin-top: 1rem;
  }
}


@media only screen and (max-width: 767px) {
  
  .header {
    padding: 0rem;
   &__title {
    padding-left: 1rem;
    text-align: center;
    margin-left: 2rem;
    padding-right: 0rem;
  }
   &__date {
    padding-left: 1rem;
    margin-left: 2rem;
   }
   &__container-date-profile{
    padding-bottom: 1rem;
   }
   
    &__logout-container {
      //max-width: 1rem;
    }
  }
  
}
